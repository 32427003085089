import React,{ createRef, useEffect, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const Mp4Player = ({ src, }) => { 

    const ref = createRef();

    const [ _player, setPlayer ] = useState(null);

    useEffect(() => {

        setPlayer(
            videojs(
                ref.current, 
                {
                    autoplay: true,
                    controls: true,
                    sources: [{ src }]
                }, 
                () => {
                    //console.log('onPlayerReady', _player)
                }
            )
        );

        return () => {

            if (_player) {

                _player.dispose();

            }

        }
    }, [ _player, src, ref ]);

    return (
        <div data-vjs-player>
            <video ref={ ref } className="video-js vjs-fluid" ></video>
        </div>
    )
};

export default Mp4Player;