import styled from 'styled-components';

export default styled.h2`
    box-sizing: border-box;    
    color: var(--color10);
    font-family: Poppins;
    font-weight: 600;
    font-size: 20px;
    line-height: 1;
    padding: 4px 0;
    margin: 0 0 2px 0;
    text-align: left;
`;