import { Fragment, useContext } from 'react'
import { HomeContext } from './../../Provider/HomeProvider'
import Title1 from '../../Component/Title1'
import Title2 from '../../Component/Title2'
import ImageCard from '../../Component/ImageCard'
import Image from '../../Component/Image'
import VideoCard from '../../Component/VideoCard'
import ButtonLinkLarge from '../../Component/ButtonLinkLarge'

export default function SectionFlashnews () {
  const { flashnews } = useContext(HomeContext)

  if (!flashnews) {
    return false
  }

  return (
    <Fragment>
      <div className='max-w-screen-xl mx-auto mb-10'>
        <Title1>Bonjour,</Title1>
        <Title2>Qu'allez-vous découvrir aujourd'hui ?</Title2>
      </div>
      <div className='max-w-screen-xl mx-auto lg:flex gap-5 md:max-xl'>
        <div className='static md:basis-3/4'>
          <section>
            <div className='static md:flex md:flex-row bg-color1 border border-solid border-color2 rounded-xl mb-6'>
              <div className='basis-1/2'>
                <ImageCard ratio={1.51} image={flashnews.principale.image}>
                  <div className='absolute bg-gradient-to-b from-transparent to-black text-white bottom-0 left-0 right-0 w-full font-poppins font-bold text-2xl p-9 rounded-xl'>
                    <a href={flashnews.principale.link}>
                      {flashnews.principale.title}
                    </a>
                  </div>
                </ImageCard>
              </div>
              <div className='basis-1/2 p-4 flex flex-col justify-around'>
                <h2 className='font-poppins text-xl font-semibold text-center md:text-start my-3 md:my-0'>
                  Vidéos récentes
                </h2>
                {flashnews.videos.map((item, i) => (
                  <article key={i}>
                    <div className='grid grid-cols-3 gap-4 mb-3 text-sm font-poppins font-bold text-color7'>
                      <div>
                        <VideoCard ratio={1.35} image={item.image} />
                      </div>
                      <div className='col-span-2 flex place-items-center'>
                        {item.title}
                      </div>
                    </div>
                  </article>
                ))}
              </div>
            </div>
            <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mb-6'>
              {flashnews.articles.map((item, i) => (
                <div key={i} className='flex flex-col gap-2.5'>
                  <Image ratio={1.5} src={item.image} />
                  <div className='font-poppins font-bold text-sm text-color7 line-clamp-3'>
                    <a href={item.link}>{item.title}</a>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className='md:basis-1/4 bg-color1 border border-solid border-color2 rounded-xl px-4 py-4 flex flex-col justify-around'>
          <h2 className='font-poppins text-xl font-semibold text-center my-3 md:my-0'>
            Articles populaires
          </h2>
          {flashnews.populaires.map((item, i) => (
            <article key={i}>
              <div className='grid grid-cols-3 gap-4 mb-3 text-sm font-poppins font-bold text-color7'>
                <div>
                  <ImageCard ratio={1.35} image={item.image} />
                </div>
                <div className='col-span-2 flex place-items-center'>
                  <a href={item.link}>{item.title}</a>
                </div>
              </div>
            </article>
          ))}
          <div className='text-center my-3 md:my-0'>
            <ButtonLinkLarge href='https://www.charisma.fr/fr/flash_main.php'>
              En voir plus
            </ButtonLinkLarge>
          </div>
        </div>
      </div>

      <div className='max-w-screen-xl mx-auto my-10 flex flew-row justify-between items-center'>
        <div>
          <Title1>Explorez</Title1>
          <Title2>Découvrez plusieurs centres d’intérêts</Title2>
        </div>
        <div>
          <ButtonLinkLarge
            href='https://www.charisma.fr/fr/flash_main.php'
            outline
          >
            Allez sur Flashnews
          </ButtonLinkLarge>
        </div>
      </div>

      <div className='max-w-screen-xl mx-auto mb-10'>
        <ul className='flex overflow-scroll text-nowrap w-full no-scrollbar'>
          {flashnews.themes.map((item, i) => (
            <li key={i} className='py-2'>
              <a
                href={item.link}
                key={i}
                className={
                  i === 0 ? 'text-nowrap label active' : 'text-nowrap label'
                }
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className='max-w-screen-xl mx-auto my-10 static lg:flex gap-5 '>
        <div className='basis-3/4'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-y-6 gap-x-4 mb-8 text-sm font-poppins font-bold text-color7'>
            {flashnews.recents.map((item, i) => (
              <article key={i}>
                <div className='mb-4'>
                  <ImageCard ratio={1.5} image={item.image} />
                </div>
                <div className='flex text-start gap-4'>
                  <div className='mb-2'>
                    <span className='label label-outline label-small'>
                      {item.theme}
                    </span>
                  </div>
                  <div className='date'>{item.date}</div>
                </div>
                <div className='col-span-2 flex place-items-center font-poppins font-bold text-xl mb-1.5'>
                  <a href={item.link}>
                    <span className='line-clamp-3'>{item.title}</span>
                  </a>
                </div>
                <div className='text-start text-base font-roboto font-normal text-color13 overflow-hidden line-clamp-3'>
                  {item.resume}
                </div>
              </article>
            ))}
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2'>
            {flashnews.societes.map((item, i) => (
              <article key={i}>
                <div className='grid grid-cols-2 gap-4 mb-3 text-sm font-poppins font-bold text-color7'>
                  <div>
                    <ImageCard ratio={1.5} image={item.image} />
                  </div>
                  <div className='flex flex-col justify-start'>
                    <div className='mt-2 md:mt-4 '>
                      <span className='label label-outline label-small'>
                        {item.theme}
                      </span>
                    </div>
                    <div className='mt-2 md:mt-4'> 
                      <a href={item.link}>{item.title}</a>
                    </div>
                    <div className='date mt-2 md:mt-4'>{item.date}</div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
        <div className='md:basis-1/4 bg-color1 border border-solid border-color2 rounded-xl px-4 py-4 flex flex-col justify-around'>
          <div className='text-center my-3 md:my-0'>
            <span className='label active'>Actualités</span>
          </div>

          <h2 className='font-poppins text-xl font-semibold text-center my-3 md:my-0'>
            Articles populaires
          </h2>
          {flashnews.actualites.map((item, i) => (
            <article key={i}>
              <div className='grid grid-cols-3 gap-4 mb-3 text-sm font-poppins font-bold text-color7'>
                <div>
                  <ImageCard ratio={1.35} image={item.image} />
                </div>
                <div className='col-span-2 flex place-items-center'>
                  <a href={item.link}>{item.title}</a>
                </div>
              </div>
            </article>
          ))}
          <div className='text-center my-3 md:my-0'>
            <ButtonLinkLarge href='https://www.charisma.fr/fr/flash_themes.php?theme=67'>Voir plus</ButtonLinkLarge>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
