import React from 'react';

const YoutubePlayer = props => { 

   
    return (
        <div 
            style={{
                position: 'relative',
                paddingBottom: '56.25%'

            }}
        >
            <iframe 
                title={props.title}
                src={`${props.src}?autoplay=1`} 
                allow="autoplay; fullscreen" allowFullScreen 
                className="video_player__youtube" 
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    left: '0px',
                    top: '0px'
                }}
                
                width="100%" 
                height="100%" 
            ></iframe>
        </div>
    );
};

export default YoutubePlayer;