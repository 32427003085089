import axios from "axios";
import _ from 'lodash';
import moment from "moment/moment";
moment.locale('fr');

export const getRefonte = async () => {
    const response = await axios('https://www.charisma.fr/fr/api/refonte.php');
    return response.data;
}

export const getArticle = async (id, type) => {
    const response = await axios('https://www.charisma.fr/fr/api/article.php', {
        params: {
            id,
            type
        }
    });
    return ArticleAdapter(response.data);
};

export const getArticles = async (ids = [], type) => {
    const promises = ids.map(async (id) => getArticle(id, type));
    return await Promise.all(promises);
}

export const getArticleFlashnews = async (id) => {
    const response = await axios('https://www.charisma.fr/fr/api/flash.php', {
        params: { id }
    });
    return ArticleFlashnewsAdapter(response.data);
};

export const getArticlesFlashnews = async (ids = [], type) => {
    const promises = ids.map(async (id) => getArticleFlashnews(id));
    return await Promise.all(promises);
}

export const getArticleTemoignage = async (id) => {
    const response = await axios('https://www.charisma.fr/fr/api/temoignage.php', {
        params: { article: id }
    });
    return ArticleTemoignageAdapter(response.data);
};

export const getArticlesTemoignage = async (ids = []) => {
    const promises = ids.map(async (id) => getArticleTemoignage(id));
    return await Promise.all(promises);
}

export const getTemoignageHome = async () => {

}

export const getThemes = async () => {
    const response = await axios('https://www.charisma.fr/fr/api/themes.php');
    return _.orderBy(
        _.map(response.data, item => ThemeAdapter(item)),
        ['title']
    );
}

export const getVideosHome = async () => {
    const response = await axios('https://www.charisma.fr/fr/api/videoHome.php');
    const result = _.map(response.data, item => videoHomeAdapter(item));
    return result;
}

export const getEvenements = async () => {
    const response = await axios('https://www.charisma.fr/fr/api/evenements.php');
    const result = _.filter(response.data, item => item.type === 'evenement');

    return result;
}

const ArticleAdapter = (item) => {
    return {
        id: item.id,
        title: item.titre,
        image: item.photo,
        resume: item.resume,
        theme: (!!item.theme) ? item.theme.nom : null,
        date: (!!item.parution) ? moment(item.parution).format('DD MMM YYYY') : null ,
        auteur: item.auteur
    };
}

const ArticleTemoignageAdapter = item => {
    return {
        id: item.id,
        title: item.titre,
        resume: item.resume,
        link: item.link,
        image: '',
        theme: 'En cours'
    }
}
 
const ArticleFlashnewsAdapter = (item) => {
    return {
        id: item.id,
        title: item.titre,
        image: item.img,
        resume: item.contenu.resume,
        link: item.uri,
        date: (!!item.parution) ?  moment(item.parution).format('DD MMM YYYY') : null,
        theme: item.theme.nom
    };
}

const ThemeAdapter = item => {
    return {
        id: item.id,
        title: item.nom,
        link: `https://www.charisma.fr/fr/flash_themes.php?theme=${item.id}`
    };
}

const videoHomeAdapter = item => {
    return {
        title: item.titre,
        artiste: item.artiste,
        src: item.source,
        image: item.photo
    }
}