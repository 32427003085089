import styled from 'styled-components';

const Wrapper = styled.div`
    /* background-color: #ccc; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => `${props.width}`};
    aspect-ratio: ${props => props.ratio};
    border-radius: 6px;
    overflow: hidden;
    

    & > img {
        height: 100%;
        object-fit: cover;
        
    }
`;



export default function Image ({ratio = 1, src = '', alt = '', initialWidth = '100%', className = "" }) {

    return (
        <Wrapper 
            className={className}
            ratio={ratio} 
            width={initialWidth} 
        >
            <img src={src} alt={alt} />
        </Wrapper>
    );
} 