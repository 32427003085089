import styled from 'styled-components';

const Link = styled.a`
    background: ${props => props.outline === "outline" ? `white`  : `transparent linear-gradient(270deg, var(--color3) 0%, var(--color4) 100%) 0% 0% no-repeat padding-box`};
    border: ${props => props.outline === "outline" ? `2px solid var(--color5)`  : 'none'};
    border-radius: 13px;
    box-sizing: border-box;
    color: ${props => props.outline === "outline" ? `var(--color6)`  : 'white'};
    display: inline-block;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
    justify-content: center;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
`;


export default function ButtonLinkLarge({ children, href, outline = false, className = ''}) {

    return (
        <Link
            href={href}
            className={className}
            outline={outline ? "outline": ""}
        >{children}</Link>
    );
};