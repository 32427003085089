import styled from 'styled-components';

export default styled.h1`
    box-sizing: border-box;
    color: var(--color9);
    font-family: Poppins;
    font-size: 30px;
    line-height:1;
    padding: 6px 0;
    margin: 0 0 2px 0;
    font-weight: bold;
    letter-spacing: 0.6px;
    text-align: left;
`;