import styled from 'styled-components';

const LabelWrapper = styled.span`
    border: 1px solid var(--color5);
    border-radius: 13px;
    color: var(--color5);
    font-family: "Poppins-SemiBold, Poppins";
    font-size: 14px;
    font-weight: 700;
    padding: 2px 12px;
`;

export default function Label({ text }) {
    return (
        <LabelWrapper>{text}</LabelWrapper>   
    );
}