import './App.css'
import SectionFlashnews from './Page/Home/SectionFlashnews'
import SectionSpirituelle from './Page/Home/SectionSpirituelle'
import SectionPureMusic from './Page/Home/SectionPureMusic'
import SectionTemoignage from './Page/Home/SectionTemoignage'
import SectionNotreEglise from './Page/Home/SectionNotreEglise'
import SectionEvenements from './Page/Home/SectionEvenements'
import SectionCharismaWorld from './Page/Home/SectionCharismaWorld'
import SectionVieEglise from './Page/Home/SectionVieEglise'
import Header from './Page/Home/Header'
import Footer from './Page/Home/Footer'

function App () {
  return (
    <>
      <Header />
      <div className='pt-5 px-4'>
        <SectionFlashnews />
        <SectionSpirituelle />
        <SectionPureMusic />
        <SectionTemoignage />
        <SectionNotreEglise />
        <SectionEvenements />
        <SectionVieEglise />
        

        <div className='max-w-screen-xl mx-auto mb-10'>
          <a href='https://don.charisma.fr/'>
            <img src='/assets/img/home/don_en_ligne.png' alt='Don en ligne' />
          </a>
        </div>

        <div className='max-w-screen-xl mx-auto mb-10 text-center'>
          <h1 className='text-center text-2xl text-color9 font-poppins font-bold mb-2'>
            ÊTES VOUS PRÊTS POUR LE CHOIX LE PLUS IMPORTANT DE VOTRE VIE ?
          </h1>
          <a href='https://www.charisma.fr/fr/viesansfin.php'>
            <img src='/assets/img/home/vie_eternelle.png' alt='Vie Eternelle' />
          </a>
        </div>

        <SectionCharismaWorld />

       
      </div>

      <Footer />
    </>
  )
}

export default App
