import axios from "axios";
import _ from 'lodash';

const CharismaVideoAdapter = (item) => {

    return {
        id: item.id,
        title: item.title,
        src: item.play,
        image: item.thumbnails.large
    };
}

const YoutubeVideoAdapter = (item) => {

    return {
        id: item.id,
        title: item.title,
        src: item.embed,
        image: item.poster,
        thumbnails: item.thumbnails
    };
}


export const getVideo = async (id) => {
    const response = await axios(`https://content.charisma.fr/web/api/media/${id}.json`);
    return CharismaVideoAdapter(response.data);
}

export const getVideos = async (ids = []) => {
    const promises = await ids.map(async (id) => getVideo(id));
    return await Promise.all(promises);
}

export const getVideosYT = async () => {
    const response = await axios(`https://youtube.charisma.fr/api/videos?limit=6&order[publishedAt]=desc`);

    return _.map(response.data['hydra:member'], v => YoutubeVideoAdapter(v)); 
}