import { Fragment, useContext } from 'react'
import { HomeContext } from '../../Provider/HomeProvider'
import Title1 from '../../Component/Title1'
import Title2 from '../../Component/Title2'
import ButtonLinkLarge from '../../Component/ButtonLinkLarge'
import ImageCard from '../../Component/ImageCard'

export default function SectionTemoignage () {
  const { temoignages } = useContext(HomeContext)

  if (!temoignages) {
    return false
  }

  return (
    <Fragment>
      <div
        id='temoignages'
        className='max-w-screen-xl mx-auto mb-10 scroll-top'
      >
        <Title1>Témoignages,</Title1>
        <Title2>Voyez ce que le Seigneur a fait !</Title2>
      </div>

      <div className='max-w-screen-xl mx-auto mb-10 lg:flex gap-5'>
        <div className='basis-2/3'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-4 mb-8 text-sm font-poppins font-bold text-color7'>
            {temoignages.alaunes.map((item, i) => (
              <article key={i}>
                <div className='mb-4'>
                  <ImageCard ratio={1.5} image={item.images.small} />
                </div>
                <div className='flex text-start gap-4'>
                  <div className='mb-2'>
                    <span className='label label-outline label-small'>
                      {item.theme.nom}
                    </span>
                  </div>
                  <div className='date'>{item.date}</div>
                </div>
                <div className='col-span-2 flex place-items-center font-poppins font-bold text-xl line-clamp-3 mb-1.5'>
                  <a href={item.link}>{item.titre}</a>
                </div>
                <div className='text-start text-base font-roboto font-normal text-color13 overflow-hidden line-clamp-3'>
                  {item.resume}
                </div>
              </article>
            ))}
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2'>
            {temoignages.articles.map((item, i) => (
              <article key={i}>
                <div className='grid grid-cols-2 gap-4 mb-3 text-sm font-poppins font-bold text-color7'>
                  <div>
                    <ImageCard ratio={1.5} image={item.images.small} />
                  </div>
                  <div className='flex flex-col justify-start'>
                    <div className='mt-2 md:mt-4 '>
                      <span className='label label-outline label-small'>
                        {item.theme.nom}
                      </span>
                    </div>
                    <div className='mt-2 md:mt-4'>
                      <a href={item.link}>{item.titre}</a>
                    </div>
                    <div className='date mt-2 md:mt-4'>{item.date}</div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
        <div className='basis-1/3'>
          <div className='bg-color1 border border-solid border-color2 rounded-xl px-4 py-4 flex flex-col justify-around'>
            <h2 className='font-poppins text-xl font-bold text-center my-4 text-color7'>
              Thème du jour :
            </h2>
            <div className='my-4 text-center'>
              <span className='label label-outline label-small'>{temoignages.themeDuJour.label}</span>
            </div>
            <div className='my-4'>
              {temoignages.themeDuJour.articles.map((item, i) => (
                <article key={i}>
                  <div className='grid grid-cols-2 gap-4 mb-3 text-sm font-poppins font-bold text-color7'>
                    <div>
                      <ImageCard ratio={1.35} image={item.images.small} />
                    </div>
                    <div className='flex flex-col justify-start'>
                      <div className='mt-2 md:mt-4 '>
                        <span className='label label-outline label-small'>
                          {item.theme.nom}
                        </span>
                      </div>
                      <div className='mt-2 md:mt-4'>
                        <a href={item.link}>{item.titre}</a>
                      </div>
                      <div className='date mt-2 md:mt-4'>{item.date}</div>
                    </div>
                  </div>
                </article>
              ))}
            </div>
            <div className='text-center my-4'>
              <ButtonLinkLarge href='https://www.charisma.fr/fr/temoignages.php'>
                Voir plus de témoignages
              </ButtonLinkLarge>
            </div>
          </div>
          <div className='bg-color1 border border-solid border-color2 rounded-xl px-4 py-4 flex flex-col justify-around my-4'>
            <h2 className='font-poppins text-xl font-bold text-center my-3 md:my-0 text-color7'>
              Et vous, qu’a fait Dieu
              <br />
              dans votre vie ?
            </h2>
            <div className='text-center my-4'>
              <ButtonLinkLarge href='#'>Envoyer mon témoignage</ButtonLinkLarge>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
