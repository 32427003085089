import Image from "./Image";
import IconPlay from './../assets/img/play.svg';

export default function VideoCard({ image, ratio }) {
    return (
        <div className="relative rounded-xl">
            <Image ratio={ratio} src={image} alt="" />
            <img className="absolute left-0 top-0 w-full h-full scale-50" src={IconPlay} alt="" />
        </div>
    )
}