import { Fragment } from 'react'
import Logo from '../../Component/Logo'
import NavBar from '../../Component/NavBar'
import Label from '../../Component/Label'

export default function Header () {
  return (
    <Fragment>
      <header>
        <Logo />
        <NavBar>
          <a href='/#'>ACCUEIL</a>
          <a href='/#votre_eglise'>VOTRE EGLISE</a>
          <a href='/#spirituelle'>VIE SPIRITUELLE</a>
          <a href='top'>EXPLOREZ</a>
          <a href='/#temoignages'>TEMOIGNAGES</a>
          <a href='https://www.charismatv.fr'>CHARISMA TV</a>
          <a href='https://www.pure-music.fr'>PURE MUSIC</a>
          <a href='https://don.charisma.fr'>
            <Label text='Faire un don' />
          </a>
        </NavBar>
      </header>
    </Fragment>
  )
}
