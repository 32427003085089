import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/gilroy/Gilroy-ExtraBold.ttf';
import './assets/fonts/Metropolis/Metropolis-Black.otf';
import './assets/fonts/avenir-roman.ttf';

import HomeProvider from './Provider/HomeProvider';
import AppProvider from './Provider/AppProvider';
import Player from './Component/Player';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppProvider>
      <HomeProvider>
        <App />
      </HomeProvider>
      <Player />
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
