import { Fragment } from 'react'

export default function SectionCharismaWorld () {
  return (
    <Fragment>
      <div className='max-w-screen-xl mx-auto mb-10 text-center'>
        <h1 className='text-center text-2xl text-color9 font-poppins font-bold mb-2'>
          DÉCOUVREZ CHARISMA WORLD
        </h1>
        <div className='grid grid-cols-4 lg:grid-cols-7 justify-around gap-4 '>
          <a href='https://groupe-scolaire-excelle.fr'>
            <img
              src='/assets/img/charisma_world/groupe_scolaire_excelle.png'
              alt='Groupe Scolaire Excelle'
            />
          </a>
          <a href='https://www.emploi-logement.com'>
            <img
              src='/assets/img/charisma_world/emploi_logement.png'
              alt='Emploi & Logement'
            />
          </a>
          <a href='https://www.editions-charisma.fr'>
            <img
              src='/assets/img/charisma_world/editions_charisma.png'
              alt='Editions Charisma'
            />
          </a>
          <a href='https://acadarts.charisma.fr/'>
            <img
              src='/assets/img/charisma_world/acadarts.png'
              alt='Académie des arts'
            />
          </a>
          <img src='/assets/img/charisma_world/king_link.png' alt='King Link' />
          <img src='/assets/img/charisma_world/eleos.png' alt='Eleos' />
          <img src='/assets/img/charisma_world/livac.png' alt='Livac' />
        </div>
      </div>
    </Fragment>
  )
}
