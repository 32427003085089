import { Fragment, useContext } from 'react'
import Title2 from '../../Component/Title2'
import { HomeContext } from '../../Provider/HomeProvider'

export default function SectionEvenements () {
  const { evenements } = useContext(HomeContext)

  if (!evenements) {
    return false
  }

  return (
    <Fragment>
      <div className='max-w-screen-xl mx-auto mb-10'>
        <Title2>Les évènements à venir</Title2>
      </div>
      {evenements.map((item, i) => (
        <div key={i} className='max-w-screen-xl mx-auto mb-10'>
          <a href={item.href}>
            <img src={item.src} alt={item.alt} className='w-full rounded-xl' />
          </a>
        </div>
      ))}
    </Fragment>
  )
}
