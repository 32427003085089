import { createRef, useEffect, useState } from 'react';

const ToggleIcon = ({ onClick, toggle}) => {
    if(toggle) {
        return (
            <button
                aria-label='toggle button'
                aria-expanded="false"
                className='cursor-pointer w-7 md:hidden flex items-center justify-center'
                onClick={onClick}
            >
                <span className='text-3xl'>&#9747;</span>
            </button>
        )
    }
    return (
        <button
            aria-label='toggle button'
            aria-expanded="false"
            className='cursor-pointer w-7 md:hidden text-end'
            onClick={onClick}
        >
            <span className='text-3xl'>&#9776;</span>
        </button>
    );
}

const NavItem = ({children}) => {
    return (<li className='pt-1.5'>{children}</li>);
}

export default function NavBar({ children }) {

    const [ toggle, setToggle ] = useState(false);

    const ref = createRef();

    useEffect(() => {
        if(ref.current) {
            if(toggle) {
                ref.current.classList.remove('-translate-y-full');
            } else {
                ref.current.classList.add('-translate-y-full');
            }
            
            if(ref.current.classList.contains('-translate-y-full')) {
                ref.current.setAttribute("aria-expanded", 'false');
            } else {
                ref.current.setAttribute("aria-expanded", 'true');
            }

        }
    }, [toggle, ref]);

    return (
        <nav >
            
            <ul 
                ref={ref} 
                className='bg-white w-full absolute border-b flex flex-col items-center -translate-y-full top-full left-0 -z-10 py-2 md:z-10 md:static md:w-full md:justify-around md:transform-none md:border-none md:flex-row'
            >
                { children.map((item, i) => (<NavItem key={i}>{item}</NavItem>)) }
            </ul>
            <ToggleIcon onClick={e => setToggle(!toggle)} toggle={toggle} />
        </nav>
    )
};