import moment from "moment";
import 'moment/locale/fr';

export const dateFormat = (date, format) => {
    if(!date) {
        return null;
    }

    let display = moment(date, "YYYY-MM-DD");
    display.locale('fr');
    return display.format(format);
}