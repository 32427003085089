import { Fragment, useContext } from 'react'
import { HomeContext } from '../../Provider/HomeProvider'
import ButtonLinkLarge from '../../Component/ButtonLinkLarge'
import Title2 from '../../Component/Title2'
import ImageCard from '../../Component/ImageCard'
import { dateFormat } from '../../utils'

export default function SectionVieEglise () {
  const { cetteSemaine, vieEglise } = useContext(HomeContext)

  const Activites = [
    {
      image: '/assets/img/home/les_cultes.png',
      title: 'Les cultes',
      resume: 'Chaque week-end, du vendredi au dimanche, les portes de Charisma Eglise Chrétienne sont ouvertes pour deux cultes différents…',
    },
    {
      image: '/assets/img/home/mouvement_des_disciples.png',
      title: 'Mouvement des disciples',
      resume: 'Le Mouvement des Disciples (MD) est l’occasion de se retrouver avec des personnes proches de notre cœur…',
    }
  ];

  if (!vieEglise) {
    return false
  }

  

  return (
    <Fragment>
      <div className='max-w-screen-xl mx-auto mb-10 text-center'>
        <ButtonLinkLarge href='#' outline>
          Voir toutes nos activités
        </ButtonLinkLarge>
      </div>
      <div className='max-w-screen-xl mx-auto mb-10 static lg:flex gap-5 justify-items-stretch'>
        <div className='basis-2/3'>
          <div className='my-4 grid grid-cols-2 md:grid-cols-3 gap-4 mb-6'>
            {Activites.map((item, i) => (
              <article key={i}>
                <div className='mb-4'>
                  <ImageCard ratio={1.5} image={item.image} />
                </div>

                <div className='col-span-2 flex place-items-center font-poppins font-bold text-xl line-clamp-3 mb-1.5'>
                  {item.title}
                </div>
                <div className='text-start text-base font-roboto font-normal text-color13 overflow-hidden line-clamp-3'>
                  {item.resume}
                </div>
              </article>
            ))}
          </div>
          <div className='my-4'>
            <Title2>Informations</Title2>
          </div>
          <img src='/assets/img/home/culte_priere.png' alt='Culte de prière' />
        </div>
        <div className='md:basis-1/3 bg-color1 border border-solid border-color2 rounded-xl px-4 py-4 flex flex-col justify-around'>
          <div className='px-4 py-4'>
            <h2 className='font-poppins text-xl font-bold text-center my-4 text-color7'>
              Vie d’église
            </h2>
            <p className='font-poppins text-sm font-bold text-start my-4 text-color7'>
              Nous célébrons la naissance de
            </p>
            {vieEglise.naissances.map((item, i) => (
              <div key={i}>
                <p className='font-poppins text-xl font-bold text-start mt-4 text-color7'>
                  {item.bebe}
                </p>
                <p className='font-poppins text-sm text-start text-color12'>
                  {dateFormat(item.date, 'DD MMM YYYY')}
                </p>
              </div>
            ))}
            <p className='font-poppins text-sm font-bold text-start my-4 text-color7'>
              Nous célébrons le mariage de
            </p>
            {vieEglise.mariages.map((item, i) => (
              <div key={i}>
                <p className='font-poppins text-xl font-bold text-start mt-4 text-color7'>
                  {item.fiances}
                </p>
                <p className='font-poppins text-sm text-start text-color12'>
                  {dateFormat(item.date, 'DD MMM YYYY')}
                </p>
              </div>
            ))}

            <h2 className='font-poppins text-xl font-bold text-center my-4 text-color7'>
              Agenda de la semaine
            </h2>
            {cetteSemaine.map((item, i) => (
              <h2 key={i} className='font-poppins text-xl font-bold text-start my-4 text-color7'>
                <a href={item.link}>{item.titre}</a>
              </h2>
            ))}
            
            <div className='text-center my-4'>
              <ButtonLinkLarge href='https://www.charisma.fr/fr/ve.php'>
                Voir plus
              </ButtonLinkLarge>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
