import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlay,
  faPause,
  faVolumeHigh,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../Provider/AppProvider'
import { CSSTransition } from 'react-transition-group'

const Wrapper = styled.div`
  & {
    .bar {
      display: grid;
      gap: 9px;
      grid-template-columns: 80px auto 80px;
      align-items: center;
      .current-time,
      .duration {
        color: #eee;
        font-size: var(--font-size-xs);
        max-width: 30px;
      }

      .seek-barwrapper {
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
      }

      .seek-bar {
        background-color: #171342;
        display: flex;
        align-items: center;
        height: 2px;
        width: 100%;
        .play-bar {
          background-color: #eee;

          height: 2px;
        }
      }
    }

    .volume-barwrapper {
      height: 20px;
      display: flex;
      align-items: center;
      width: 100%;
    }
    .volume-bar {
      background-color: #171342;
      height: 2px;
      width: 100%;
      .volume-status {
        background-color: #eee;
        height: 2px;
      }
    }
  }
`

const Player = () => {
  const { player, audio, onClose } = useContext(AppContext);
  const ref = createRef();
  const progressRef = createRef();
  const volumeRef = createRef();
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [paused, setPaused] = useState(false)
  const [volume, setVolume] = useState(0)

  useEffect(() => {
    audio.addEventListener('timeupdate', onTimeUpdate)
    audio.addEventListener('loadedmetadata', onLoadedMetadata)
    audio.addEventListener('pause', () => {
      setPaused(true)
    })
    audio.addEventListener('play', () => {
      setPaused(false)
    })

    return () => {
      audio.removeEventListener('timeupdate', onTimeUpdate)
      audio.removeEventListener('loadedmetadata', onLoadedMetadata)
    }
  })

  const onLoadedMetadata = useCallback(() => {
    setCurrentTime(audio.currentTime)
    setDuration(audio.duration)
    setVolume(audio.volume)
  }, [audio])

  const getCurrentTime = useCallback(() => {
    return new Date(currentTime * 1000).toISOString().substr(11, 8)
  }, [currentTime])

  const getDuration = useCallback(() => {
    return new Date(duration * 1000).toISOString().substr(11, 8)
  }, [duration])

  const getProgression = useCallback(() => {
    const _progression = Math.round((currentTime * 100) / duration)

    if (isNaN(_progression)) {
      return 0
    }
    return `${_progression}%`
  }, [currentTime, duration])

  const getVolume = useCallback(() => {
    return parseInt(volume * 100)
  }, [volume])

  const onDefineVolume = e => {
    e.preventDefault()
    e.stopPropagation()
    const _rect = volumeRef.current.getBoundingClientRect()
    const _volume = e.nativeEvent.offsetX / _rect.width
    setVolume(_volume)
    audio.volume = _volume
  }

  const onTimeUpdate = useCallback(() => {
    setCurrentTime(audio.currentTime)
  }, [audio])

  const onPlay = e => {
    e.preventDefault()
    e.stopPropagation()
    audio.play()
  }

  const onPause = e => {
    e.preventDefault()
    e.stopPropagation()
    audio.pause()
  }

  const onSeeking = e => {
    e.preventDefault()
    e.stopPropagation()

    const _rect = progressRef.current.getBoundingClientRect()

    const _tmp = {
      x: e.nativeEvent.offsetX,
      width: _rect.width,
      duration: audio.duration
    }
    const _currentTime = (_tmp.x * audio.duration) / _tmp.width
    audio.currentTime = _currentTime
  }

  return (
    <CSSTransition
      in={ !!player }
      nodeRef={ref}
      timeout={300}
      classNames='alert'
      unmountOnExit
    >
      <Wrapper ref={ref}>
        <div className='w-full bg-color10 fixed bottom-0 text-white p-2'>
          <div className='flex'>
            <div className='flex-none w-14 text-center flex items-center align-middle justify-center'>
              {paused === true ? (
                <FontAwesomeIcon
                  icon={faPlay}
                  className='text-2xl text-white mr-2 bg-color5 p-2 rounded-full w-6 h-6'
                  onClick={onPlay}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faPause}
                  className='text-2xl text-white mr-2 bg-color5 p-2 rounded-full w-6 h-6'
                  onClick={onPause}
                />
              )}
            </div>
            <div className='flex-1 text-center'>
              <div className='font-montserrat text-base font-medium'>
                { player ? `${player.title} / ${player.artiste} - ${player.album}` : ''}
              </div>
              <div className='bar'>
                <div className='current-time'>{getCurrentTime()}</div>
                <div className='seek-barwrapper' onClick={onSeeking}>
                  <div ref={progressRef} className='seek-bar'>
                    <div
                      className='play-bar'
                      style={{ width: getProgression() }}
                    />
                  </div>
                </div>
                <div className='duration'>{getDuration()}</div>
              </div>
            </div>
            <div className='flex-none w-48 text-center flex items-center align-middle justify-center'>
              <FontAwesomeIcon
                icon={faVolumeHigh}
                className='text-2xl text-white mr-2'
              />

              <div
                ref={volumeRef}
                className='volume-barwrapper'
                onClick={onDefineVolume}
              >
                <div className='volume-bar'>
                  <div
                    className='volume-status'
                    style={{ width: `${getVolume()}%` }}
                  />
                </div>
              </div>

              <FontAwesomeIcon
                icon={faXmark}
                className='text-2xl text-white mr-2 bg-red-500 p-2 ml-4 rounded-full w-8 h-4'
                onClick={e => onClose()}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </CSSTransition>
  )
}
export default Player
