import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const audio = new Audio();

export const AppContext = createContext({
  audio: null,
  player: null,
  setPlayer: () => {},
  onPlay: () => {}
})

export const AppProvider = props => {
  const [ player, setPlayer] = useState(false);

  const onPlay = (src, title = '', artiste = '', album = '') => {
    audio.pause();
    audio.src = src;
    audio.play();

    setPlayer({
        title,
        artiste,
        album
    });
  }

  const onClose = () => {
    audio.pause();
    audio.src = '';
    setPlayer(false);
  }

  return (
    <AppContext.Provider value={{ audio, player, onPlay, onClose }} >{props.children}</AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppProvider
