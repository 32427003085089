import styled from 'styled-components';
import logoSrc from "./../assets/img/logo.png";

const LogoWrapper = styled.div`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 0;

    & > img {
        max-width: 260px;
        display: block;
    }
`;

export default function Logo() {
    return (
        <LogoWrapper>
            <img 
                src={logoSrc}
                alt="charisma.fr" 
            />
        </LogoWrapper>
    )
};